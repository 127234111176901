import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import {BsWhatsapp} from 'react-icons/bs';


const Menu = () => {
  const [menu, setMenu] = useState(false);
  return (
    <div
      className={`bg-white dark:bg-white text-black xl:max-h-full xl:max-w-full menuContainer fixed z-50 right-0 xl:top-14 lg:top-10 top-7 flex flex-col xl:flex-row xl:items-center py-4 px-2 xl:px-0 transform transition-all duration-500 overflow-hidden ${
        !menu ? "menuSmall" : "menuBig"
      }`}
    >
      <FeatherIcon
        icon="menu"
        className="absolute cursor-pointer xl:hidden ml-3  -mt-1"
        onClick={() => setMenu(!menu)}
      />
      <ul className="flex xl:flex-row flex-col justify-center mt-10 mb-6 xl:mt-0 xl:mb-0">
        <a href="#Inicio">
          <li className="my-2 xl:my-0 lg:ml-8 sm:ml-5 ml-3 xs:text-xs sm:text-sm lg:text-base cursor-pointer">
            Inicio
          </li>
        </a>
        <a href="#Nosotros">
          <li className="my-2 xl:my-0 lg:ml-8 sm:ml-5 ml-3 xs:text-xs sm:text-sm lg:text-base cursor-pointer">
            Nosotros
          </li>
        </a>
        <a href="#Tratamientos">
          <li className="my-2 xl:my-0 lg:ml-8 sm:ml-5 ml-3 xs:text-xs sm:text-sm lg:text-base cursor-pointer">
            Tratamientos
          </li>
        </a>
        <a href="#Clinica">
          <li className="my-2 xl:my-0 lg:ml-8 sm:ml-5 ml-3 xs:text-xs sm:text-sm lg:text-base cursor-pointer">
            Nuestra Clínica
          </li>
        </a>
        
        <a href="#Contacto">
          <li className="my-2 xl:my-0 lg:ml-8 sm:ml-5 ml-3 xs:text-xs sm:text-sm lg:text-base cursor-pointer">
            Contacto
          </li>
        </a>
      </ul>
      <div className="xl:ml-5 flex flex-row justify-center items-center">
      <a href="https://www.facebook.com/besaresodontologia-103114652395182/" target="_blank">
        <div className="mx-2 xl:w-12 xl:h-12 lg:w-10 lg:h-10 w-8 h-8 rounded-full flex justify-center items-center border-2 border-black cursor-pointer">
          <FeatherIcon
            icon="facebook"
            className="xl:w-6 xl:h-6 lg:w-5 lg:h-5 w-4 h-4"
          />
        </div>
        </a>
        <a href="https://instagram.com/besaresodontologia?igshid=YmMyMTA2M2Y=" target="_blank">
        <div className="mx-2 xl:w-12 xl:h-12 lg:w-10 lg:h-10 w-8 h-8 rounded-full flex justify-center items-center border-2 border-black cursor-pointer">
          <FeatherIcon
            icon="instagram"
            className="xl:w-6 xl:h-6 lg:w-5 lg:h-5 w-4 h-4"
          />
        </div>
        </a>
        <a href="mailto:besaresodontologia@gmail.com">
        <div className="mx-2 xl:w-12 xl:h-12 lg:w-10 lg:h-10 w-8 h-8 rounded-full flex justify-center items-center border-2 border-black cursor-pointer">
          <FeatherIcon
            icon="mail"
            className="xl:w-6 xl:h-6 lg:w-5 lg:h-5 w-4 h-4"
          />
        </div>
        </a>
        <a href="https://wa.link/e0hkwg" target="_blank">
        <div className="mx-2 xl:w-12 xl:h-12 lg:w-10 lg:h-10 w-8 h-8 rounded-full flex justify-center items-center border-2 border-black cursor-pointer">
        <BsWhatsapp className="lg:h-5 lg:w-5 xl:h-6 xl:w-6"/>
        </div>
        </a>
      </div>
    </div>
  );
};


export default Menu;
