import FadeGallery from "./Components/FadeGallery/FadeGallery";
import Menu from "./Components/Menu/Menu";
import "./index.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Avatar from "./Components/Avatar";
import React from "react";
import Map from "./Components/Map";
import ReactPlayer from "react-player/file";
import {FiMapPin, FiPhoneCall, FiMail} from 'react-icons/fi';


function App() {
  const imagesFade = ["fotos/BESARES0008.jpg", "fotos/BESARES0706.jpg"];
  const imagesCarousel = [
    "fotos/BESARES0220.jpg",
    "fotos/BESARES0518.jpg",
    "fotos/BESARES0360.jpg",
    "fotos/BESARES0554.jpg",
    "fotos/BESARES0552.jpg",
    "fotos/BESARES0560.jpg",
    "fotos/BESARES0461.jpg",
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="App" id="Inicio">
      <div className="absolute w-full h-full">
        <Menu />
        <a href="https://wa.link/e0hkwg" target="_blank">
        <img className="fixed right-6 bottom-10 lg:right-8 h-16 md:h-24  z-50 drop-shadow-xl cursor-pointer" src="whatsappLogo.png" alt="mensaje directo whatsapp"/>
        </a>
      </div>
      <div id="Portada" className="flex flex-col lg:flex-row relative h-auto">
        <img
          src="LogoBESARES.png"
          alt="logo besares"
          className="hidden lg:block ml-5 mt-5 mb-5 lg:h-40 xl:h-56 lg:w-auto  z-40 lg:ml-14 lg:mr-14 lg:mt-6 xl:mt-14 w-32 flex-grow-0"
        />
        <img
          src="LogoBESARES-sm.png"
          alt="logo besares"
          className="lg:hidden w-32 ml-5 mt-2 overflow-hidden"
        />
        <FadeGallery images={imagesFade} />

        <div className="bg-white dark:bg-white z-40 titleContainer w-full lg:absolute xl:bottom-10 lg:bottom-4">
          <h2 className="mx-8 md:mx-12 leading-none text-center lg:text-left">
            Clínica Dental Maxilofacial
          </h2>
        </div>
      </div>
      <div className="mt-16 p-12 lg:p-24">
        <h1 id="Mision" className="uppercase">
          Misión
        </h1>
        <div className="line h-px mb-10 mt-4 w-full lg:w-1/2" />
        <p className="">
          Buscamos la excelencia para tu salud bucal, con un equipo
          interdisciplinario capacitado para resolver todos los inconvenientes
          que puedas tener. Nos caracteriza la unión entre docencia y
          experiencia, además de contar con la ultima tecnología para realizar
          todos los tratamientos. Con la calidez que requiere nuestra profesión,
          estamos para ayudarte, queremos conocerte, te esperamos…
        </p>
      </div>
      <Carousel
        responsive={responsive}
        infinite
        swipeable
        draggable
        showDots={false}
        arrows={false}
        autoPlay
        autoPlaySpeed={5000}
        transitionDuration={1000}
      >
        {imagesCarousel.map((image) => (
          <img
            key={image}
            src={image}
            className="pointer-events-none select-none h-full w-full"
          />
        ))}
      </Carousel>
      <div
        id="Nosotros"
        className="mt-16 px-12 pb-12 lg:px-24 lg:pb-24 grid grid-cols-1 lg:grid-cols-2"
      >
        <div className="grid grid-cols-2 order-1 lg:-order-1 xs:mr-2 gap-2 items-center  lg:mt-0 mt-10">
          <div className="text-center w-full overflow-visible xs:whitespace-nowrap">
            <Avatar image="fotos/BESARES0820.jpg" />
            <p>Federico Mariluz</p>
            <p>M.N. 37640</p>
          </div>
          <div className="text-center w-full overflow-visible xs:whitespace-nowrap">
            <Avatar image="fotos/BESARES0708.jpg" />
            <p>Diego Llebaria</p>
            <p>M.N. 36359</p>
          </div>
        </div>
        <div className="lg:text-right lg:flex lg:flex-col">
          <h1 className="uppercase">Nosotros</h1>
          <div className="line h-px mb-10 mt-4 w-1/2 lg:w-3/4 place-self-end" />
          <p>
            Somos un equipo formado en la Universidad de Buenos Aires,
            especialistas en cirugía bucomaxilofacial y rehabilitación oral. Los
            años de experiencia en atención sanitaria y docencia universitaria,
            nos reúne como equipo de trabajo para llegar a la excelencia que vos
            te mereces. No te demores en conocernos, te esperamos. Gracias por
            su tiempo.
          </p>
        </div>
      </div>
      <div
        id="Tratamientos"
        className="relative pt-4 lg:mr-24 mr-0"
        style={{ backgroundColor: "#BAE1EE" }}
      >
        <h1 style={{ color: "white" }} className="uppercase mx-12 lg:mx-24">
          Tratamientos
        </h1>
        <div
          style={{ backgroundColor: "white" }}
          className="mx-12 lg:mx-24 line h-px mb-10 mt-4 w-3/4 lg:w-1/2"
        />
        <div className="mt-14 h-full lg:h-80 overflow-hidden items-center transform lg:translate-x-24">
          <img
            src="fotos/BESARES0645.jpg"
            className="transform lg:-translate-y-1/2"
          />
        </div>
        <div className="mx-12 lg:mx-24 pt-4 pb-4 especialidades flex flex-col">
          <span>IMPLANTES</span>
          <span>ORTODONCIA</span>
          <span>CIRUGIA MAXILOFACIAL</span>
          <span>URGENCIAS</span>
          <span>PERIODONCIA</span>
          <span>ENDODONCIA</span>
          <span>ESTETICA DENTAL</span>
          <span>BLANQUEAMIENTO</span>
          <span>ODONTOPEDIATRIA</span>
        </div>
      </div>
      <div className="mt-16 p-12 lg:p-24">
        <h1 id="Clinica" className="uppercase">
          Nuestra Clínica
        </h1>
        <div className="line h-px mb-10 mt-4 w-full lg:w-1/2" />
        <div className="w-full h-auto">
          <ReactPlayer
            className="react-player"
            loop
            playing
            autoPlay
            muted
            url="Institucional-completo.mp4"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </div>
      <div id="Contacto" className="flex flex-col p-6 lg:p-12">
        <h1 className="uppercase place-self-end">Contacto</h1>
        <div className="line h-px mb-10 mt-4 w-1/2 place-self-end" />
      </div>
      <div className="flex flex-col px-6 lg:px-24 mb-12 text-black">
        <div className="mb-10 lg:mb-16 flex flex-row items-center">
        <FiMapPin className="xl:w-10 xl:h-10 lg:w-7 lg:h-7 w-6 h-6"/>
        <p className="ml-6 lg:ml-8">Besares 2477, 3ro D, CABA - Argentina</p>
        </div>
        <div className="mb-10 lg:mb-16 flex flex-row items-center">
        <FiMail className="xl:w-10 xl:h-10 lg:w-7 lg:h-7 w-6 h-6"/>
        <p className="ml-6 lg:ml-8">odontologiabesares@gmail.com</p>
        </div>
        <div className="mb-10 lg:mb-16 flex flex-row items-center">
        <FiPhoneCall className="xl:w-10 xl:h-10 lg:w-7 lg:h-7 w-6 h-6"/>
        <p className="ml-6 lg:ml-8">11-3183-3547</p>
        </div>
        <div className="my-4 relative z-40">
          <Map />
        </div>
      </div>
      <div
        className="w-full h-20 xs:h-32 md:h-48 lg:h-52 xl:h-64 py-4 px-10 text-center relative"
        style={{ backgroundColor: "#BAE1EE" }}
      >
        <img
          src="LogoBESARES.png"
          alt="logo besares"
          className="hidden lg:block float-left h-full"
        />
        <img
          src="LogoBESARES-sm.png"
          alt="logo besares"
          className="lg:hidden float-left h-full mt-2"
        />
        <p className="xl:left-1/2 transform xl:-translate-x-1/2 bottom-4 right-10 xl:right-auto absolute">
        Besares 2477, 3ro D, CABA - Argentina
        </p>
      </div>
    </div>
  );
}

export default App;
