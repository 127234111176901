import React from 'react'

const Avatar = ({image}) => {
  return (
    <div className='grid grid-cols-1 p-4 h-auto -ml-4'>
        <div style={{paddingBottom:"100%"}} className='rounded-full h-0 w-full flex justify-center xs:mx-2  relative overflow-hidden'>
            <img className='trsansform xs:-translate-x-4 translate-y-4 scale-125 absolute' src={image}/>
        </div>
    </div>
  )
}

export default Avatar