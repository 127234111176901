import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "../index.css"

const Map = () => {
  return (
    <MapContainer center={[-34.545180665039354, -58.47167879815179]} zoom={16} scrollWheelZoom={true} zoomControl={false} dragging={true}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={[-34.545180665039354, -58.47167879815179]}>
    </Marker>
  </MapContainer>
  )
}

export default Map