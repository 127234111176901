import React,{useState,useEffect,useRef} from 'react'

const FadeGallery = ({images}) => {
    const [currentSlide,setCurrentSlide]=useState(0)

    const slideChange=()=>{
                setCurrentSlide(prevSlide=>prevSlide==images.length-1?0:prevSlide+1)
    }

    useEffect(() => {
        setInterval(()=>slideChange(),5000)
    }, [])

    return (
        <div className='w-full relative h-auto items-center'>
            {images.map((img,id)=>
                <img className={`${currentSlide==id?"opacity-100":"opacity-0"} ${id != 0 &&"absolute -translate-y-full"} object-cover transform transtion ease-in-out duration-1000`} key={id} src={img}/>
            )}
        </div>
    )
}

export default FadeGallery
